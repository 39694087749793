<template>
  <div style="padding-bottom: 20px">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div>
            <h3>院校专业</h3>
            <div class="row">
              <b-form-group
                class="col-lg-4 col-12 order-1 order-lg-1"
                id="input-group-1"
                label="等级:"
                label-for="input-1"
              >
                <b-form-select
                  id="input-1"
                  v-model="params.subject_level__name"
                  :options="levels"
                  @change="getSubjects"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                class="col-lg-4 col-12 order-1 order-lg-1"
                id="input-group-2"
                label="主方向:"
                label-for="input-2"
              >
                <b-form-select
                  id="input-2"
                  v-model="params.subject_type_main__name"
                  :options="mainTypes"
                  @change="getSubjects($event,true)"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                v-if="params.subject_type_main__name != '全部'"
                class="col-lg-4 col-12 order-1 order-lg-1"
                id="input-group-3"
                label="次方向:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="params.subject_type_sub__name"
                  :options="subTypes"
                  @change="getSubjects"
                ></b-form-select>
              </b-form-group>
            </div>
            <div style="padding-top: 10px">
              <b-alert v-model="reminder" variant="danger">
                无此方向的专业
              </b-alert>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="subjects">
          <div class="project-item-wrapper">
            <ul class="sidebar-list">
              <li v-for="(subject, index) in subjects" :key="index">
                <SubjectItem :subject="subject" />
              </li>
            </ul>
          </div>
          <div style="padding-top:100px;">
            <infinite-loading @infinite="infiniteHandler">
              <div slot="no-more">已无更多专业</div>
            </infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectItem from "@/components/SubjectItem";
import { schoolService } from "../services/school";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    SubjectItem,
    InfiniteLoading,
  },
  data() {
    return {
      reminder: false,
      subjects: "",
      levels: ["全部"],
      mainTypes: ["全部"],
      subTypes: ["全部"],
      schools: ["全部"],
      subjectTypes:'',
      next: "",
      params: {
        subject_level__name: "全部",
        subject_type_main__name: "全部",
        subject_type_sub__name: "全部",
        school__name_chs: "全部",
      },
    };
  },
  created() {
    //this.$nextTick(() => {
    //this.subjects = this.schoolSubjects[this.form.level].subjects;
    //});
    this.getSubjects();
    this.getMainTypes();
    this.getLevels();
  },
  methods: {
    async getSubjects(event,chooseMain) {
      if(chooseMain){
        this.params.subject_type_sub__name = "全部"
        this.getSubTypes(this.getMainTypeId(event))
      }
      let params = "?";
      for (const [key, value] of Object.entries(this.params)) {
        if (value != "" && value != "全部") {
          params = params + key + "=" + value + "&";
        }
      }
      let results = await schoolService.getAllSubjects(params);
      this.subjects = results.results;
      this.next = results.next;
    },
    getMainTypeId(name){
      for(let i=0;i<this.subjectTypes.length;i++){
        if(this.subjectTypes[i].name == name){
          return i
        }
      }
    },
    async getLevels() {
      let results = await schoolService.getSubjectLevels();
      for (let i = 0; i < results.length; i++) {
        this.levels.push(results[i].name);
      }
    },
    async getMainTypes() {
      let results = await schoolService.getSubjectTypes();
      this.subjectTypes = results
      for (let i = 0; i < results.length; i++) {
        this.mainTypes.push(results[i].name);
      }
    },
    async getSubTypes(id) {
      this.subTypes=["全部"]
      for (let i = 0; i < this.subjectTypes[id].subject_type_sub.length; i++) {
        this.subTypes.push(this.subjectTypes[id].subject_type_sub[i].name)
      }
    },
    infiniteHandler($state) {
      var that = this;
      if (!that.next) {
        $state.complete();
        return;
      }
      schoolService.getMore(that.next).then((data) => {
        if (data.next) {
          that.next = data.next;
          that.subjects = that.subjects.concat(data.results);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
};
</script>